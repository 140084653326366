import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import BasementVideo from '../videos/basement-layout.mp4';

export default function Services() {
  return (
    <div className=" bg-white">
      <div className="page-template">
        <div className=" xl:3/12 flex w-full flex-col pr-0 lg:w-5/12 lg:pr-12 xl:w-5/12">
          <h1 className="page-title">RENDERING & CAD SERVICES</h1>
          <p className="mt-4 text-xl text-primary">
            A picture is worth a thousand words. Throughout the design process,
            we use the sophistication of software to show you what your project
            will look like ahead of construction. CAD (computer-aided design)
            offers a 3D perspective of the completed design concept, not only
            for you, the client, but also for the contractor(s).
          </p>
          <p className="mt-4 text-xl text-primary">
            Development and Planning are key fundamentals to a successful
            project. Our plans, which are prepared using CAD software, ensure
            that fine details are addressed, minimizing the chance for errors
            later on. Throughout the design stages, far too often the
            contractor(s) need detailed information for building permits,
            ordering materials or to advise other tradespeople, so the need for
            a central point of information is essential.
          </p>
        </div>
        <div className=" mx-auto mt-10 flex w-full items-center justify-center lg:mt-0 lg:w-7/12 xl:w-6/12">
          <div className="relative">
            {/* <StaticImage
              src="../images/overall-basement.jpg"
              layout="constrained"
            /> */}
            <video controls>
              <source src={BasementVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className=" mx-auto flex  max-w-screen-2xl  flex-col  px-4  sm:px-6  lg:flex-row lg:px-8">
        <div className=" flex w-full flex-col ">
          <div className="flex flex-col">
            <h2 className="text-xl font-bold tracking-widest text-primary sm:text-2xl">
              DESIGN SERVICES
            </h2>
            <p className="mt-2 text-xl text-primary">
              We offer a variety of residential and commercial design services
              that can be customized to meet your individual needs
            </p>
          </div>
          <div className=" flex flex-col lg:flex-row">
            <div className="flex w-full flex-col sm:flex-row lg:w-3/5 lg:flex-col xl:w-1/3">
              <div className="relative mt-4 sm:w-1/2  lg:mx-auto lg:w-full xl:w-3/4">
                <ul className="mt-2 list-inside list-disc text-xl text-primary">
                  <li>Floor Plans & 3D Renderings</li>
                  <li>New Build/Custom Build</li>
                  <li>Renovation/Remodel</li>
                  <li>Finish & Fixture Selections</li>
                  <li>Interior Decorating</li>
                  <li>Kitchen & Bath Design</li>
                  <li>Design & Project Management</li>
                  <li>Millwork Design</li>
                  <li>Colour Consultation</li>
                </ul>
              </div>
              <div className=" relative mx-auto mt-4 sm:w-1/2 lg:w-11/12 xl:w-3/4">
                <StaticImage src="../images/floorplan-master-bedroom.jpg" />
              </div>
            </div>
            <div className=" mt-8 flex w-full flex-col items-center justify-center xl:w-2/3">
              <div className="relative">
                <StaticImage src="../images/kitchen-mockup.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" mx-auto flex max-w-screen-2xl  flex-col  px-4  py-12  sm:px-6 sm:py-16 lg:flex-row lg:px-8">
        <div className=" flex w-full flex-col ">
          <h2 className="text-xl font-bold tracking-widest text-primary sm:text-2xl">
            DESIGN PACKAGES
          </h2>
          <p className="mt-2 text-xl text-primary">
            We understand that not everyone has the luxury of working with an
            interior designer; however, we believe that everyone deserves to
            live their best life in a well-designed space! We offer a variety of
            services that can be customized to meet your individual needs.
          </p>
          <h2 className="mt-8 text-xl font-bold tracking-widest text-primary sm:text-2xl">
            INITIAL DESIGN CONSULTATION
          </h2>
          <p className="mt-2 text-xl text-primary">
            During this 60-90 minute onsite meeting, we will gain a better
            understanding of your project’s scope, your style, your needs and
            desires for the space, take preliminary photos and if required,
            collect rough site measurements. We will get to view your space
            first-hand and determine whether the project’s scope, timeline and
            budget align. We can also provide you with insightful advice;
            suggest recommended vendors , sketch out preliminary ideas, pull
            paint swatches and so much more! In other words, we’ll collect as
            much information as we can fit into a 90 minute session. All
            projects are subject to schedule availability.
          </p>
          <h2 className="mt-8 text-xl font-bold tracking-widest text-primary sm:text-2xl">
            FULL SERVICE PERSONALIZED INTERIOR DESIGN PACKAGE
          </h2>
          <p className="text-xl text-primary">
            This is the complete turnkey package or what we refer to as the
            luxury experience. Whether you would like to be part of the process
            or not, this is our most luxe package allowing you to leave your
            project in the hands of professionals with guaranteed results. We
            will alleviate the stress and work hard to give you the home of your
            dreams! If this package sounds right for you, it all starts with an
            Initial Consultation. At the end of our meeting we’ll review next
            steps. All projects are subject to schedule availability.
          </p>
          <h2 className="mt-8">
            <span className="text-xl font-bold tracking-widest text-primary sm:text-2xl">
              INTERIOR DESIGN CONSULTING PACKAGES
            </span>
            <p>
              (These packages allow you to skip the initial design consultation
              and get down to business)
            </p>
          </h2>
          <p className="mt-2 text-xl text-primary">
            These packages are ideal for clients who may have an ongoing project
            or building a new home, and may need some guidance or an expert eye
            from time to time. All projects are subject to schedule
            availability.
          </p>
          <div className="mt-12">
            <p className="flex items-center bg-secondary p-5 text-center text-xl font-medium tracking-widest text-white">
              Blocks of time are available for purchase in increments of 2, 5 or
              10 hours. The hours can be put towards multiple projects and are
              valid for two years from the date of purchase.
            </p>
          </div>
        </div>
      </div>
      <div className=" mx-auto flex  max-w-screen-2xl  flex-col px-4 py-12 sm:px-6 sm:pb-12 md:pb-12 md:pt-0 lg:px-8 xl:flex-row">
        <div className=" flex w-full flex-col pr-12  xl:w-5/12">
          <h2 className="text-xl font-bold tracking-widest text-primary sm:text-2xl">
            HELP ME DECIDE
          </h2>
          <p className="mt-4 text-xl text-primary">
            This package is ideal for the client who knows what they like but
            needs a little reassurance from a professional to help finalize
            their choices. It provides expert advice from us, your interior
            design partner.
          </p>

          <h2 className="mt-8 text-xl font-bold tracking-widest text-primary sm:text-2xl">
            THE PROBLEM SOLVER
          </h2>
          <p className="mt-4 text-xl text-primary">
            This package is for situations where you may need a third-party to
            intervene. Whether you can’t agree with your partner or have an
            issue onsite that requires a solution. This is a great package for
            DIY homeowners.
          </p>
          <h2 className="mt-8 text-xl font-bold tracking-widest text-primary sm:text-2xl">
            GET ME STARTED
          </h2>
          <p className="mt-4 text-xl text-primary">
            This package is suited to those who require a bit of inspiration or
            need a vision, they desire change but don’t know what they want or
            how to get started and need help with the design and ideas but once
            determined, can manage the details themselves.
          </p>
        </div>
        <div className=" mx-auto mt-10 flex w-full items-center justify-center xl:mt-0  xl:w-7/12">
          <div className="relative">
            <StaticImage
              src="../images/dining-into-kitchen.jpg"
              layout="constrained"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
