import React from 'react';
import Layout from '../components/layout';
import Services from '../components/services';
import Seo from '../components/seo';

export default function ServicesPage() {
  return (
    <Layout>
      <Seo
        title="Interior Design Serivces"
        description="Visualizing Designs: Our CAD Services Bring Your Project to Life Before Construction, and Our Design Packages Cater to Every Need – from Full-Service Luxury to Expert Guidance."
      />
      <Services />
    </Layout>
  );
}
